import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../_shared/services/app.service';
import { environment } from '../../environments/environment';
import { PrintType } from '../_shared/services/quote.service';

@Component({
    selector: 'app-next-steps',
    templateUrl: './next-steps.component.html',
    styleUrls: ['./next-steps.component.scss'],
})
export class NextStepsComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
        public appService: AppService
    ) {
        matIconRegistry.addSvgIcon(
            'piggy-bank',
            domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/piggy-bank.svg')
        );
        matIconRegistry.addSvgIcon(
            'coin-stack',
            domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/coin-stack.svg')
        );
    }

    public sapDocumentId: string;
    public documentId: string;
    public latestRevision: string;
    public externalApp: boolean;
    public printType: PrintType;
    public callbackUrl: string;
    public serviceConfiguratorUrl: string;

    public ngOnInit() {
        const { documentId, latestRevision, sapDocumentId, externalApp, printType, callbackUrl } =
            this.activatedRoute.snapshot.queryParams;
        this.documentId = documentId;
        this.latestRevision = latestRevision;
        this.sapDocumentId = sapDocumentId;
        this.externalApp = externalApp === 'true';
        this.serviceConfiguratorUrl = `${environment.http.serviceConfiguratorBaseUrl}/configuration/new?offerNumber=${this.sapDocumentId}`;
        this.printType = printType || 'L';
        this.callbackUrl = callbackUrl;
    }
}
