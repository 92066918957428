import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';

export interface GenericArticleCondition {
    materialNumber: string;
    conditionValue: string;
}

export interface GenericArticleQuotationText {
    id: string;
    text: string;
    materialNumber: string;
}
const GENERIC_ARTICLE_PRICE = 'GENERIC-ARTICLE-PRICE';
const GENERIC_ARTICLE_TEXT = 'GENERIC-ARTICLE-TEXT';
type GenericArticleValue = { [key: string]: string };

interface EnvironmentDiffEntry {
    [GENERIC_ARTICLE_PRICE]?: GenericArticleValue;
    [GENERIC_ARTICLE_TEXT]?: GenericArticleValue;
}

interface EnvironmentDiffAddedRemoved {
    added: { value: GenericArticleValue }[];
    removed: GenericArticleValue[];
}

interface EnvironmentDiff {
    added: EnvironmentDiffEntry;
    removed: EnvironmentDiffEntry;
    modified: {
        [GENERIC_ARTICLE_PRICE]?: EnvironmentDiffAddedRemoved;
        [GENERIC_ARTICLE_TEXT]?: EnvironmentDiffAddedRemoved;
    };
}

export interface CachedDiscountCategory {
    category: string;
    discount: number;
}

export interface ProductCache {
    categories: CachedDiscountCategory[];
    conditions: GenericArticleCondition[];
    quotationTexts: GenericArticleQuotationText[];
}

@Injectable({
    providedIn: 'root',
})
export class ProductsCategoryService {
    constructor(private httpService: HttpService) {}
    private productCache: ProductCache = {
        categories: [],
        conditions: [],
        quotationTexts: [],
    };

    public haveProductsChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private updateProductCategoryDiscountsInCache(envDiff: any) {
        const added = [];
        const removed = [];
        const categoryName = 'CATEGORY-BDIS';

        if (envDiff.added[categoryName]) {
            const addedKeys = Object.keys(envDiff.added[categoryName]);
            addedKeys.forEach((k) => added.push({ category: k, discount: envDiff.added[categoryName][k] }));
        }

        if (envDiff.removed[categoryName]) {
            const removedKeys = Object.keys(envDiff.removed[categoryName]);
            removedKeys.forEach((k) => removed.push({ category: k, discount: envDiff.removed[categoryName][k] }));
        }

        if (envDiff.modified[categoryName]) {
            envDiff.modified[categoryName].removed.forEach((r) => {
                const removedKeys = Object.keys(r);
                removedKeys.forEach((k) => removed.push({ category: k, discount: r[k] }));
            });

            envDiff.modified[categoryName].added.forEach((a) => {
                const addedKeys = Object.keys(a.value);
                addedKeys.forEach((k) => added.push({ category: k, discount: a.value[k] }));
            });
        }

        this.productCache.categories = this.productCache.categories.filter(
            (c) => !removed.find((r) => c.category === r.category && c.discount === r.discount)
        );

        added.forEach((a) => {
            if (!this.productCache.categories.find((r) => a.category === r.category && a.discount === r.discount)) {
                this.productCache.categories.push(a);
            }
        });
    }

    private updateGenericProductArticleConditions(envDiff: EnvironmentDiff) {
        if (envDiff.added[GENERIC_ARTICLE_PRICE]) {
            this.productCache.conditions = Object.entries(envDiff.added[GENERIC_ARTICLE_PRICE]).map(([key, value]) => ({
                materialNumber: key,
                conditionValue: value,
            }));
        }
        if (envDiff.added[GENERIC_ARTICLE_TEXT]) {
            this.productCache.quotationTexts = Object.entries(envDiff.added[GENERIC_ARTICLE_TEXT]).map(
                ([key, value]) => ({
                    id: '0001',
                    materialNumber: key,
                    text: value,
                })
            );
        }
    }

    public updateCache(envDiff: EnvironmentDiff) {
        const before = JSON.stringify(this.productCache);

        this.updateProductCategoryDiscountsInCache(envDiff);
        this.updateGenericProductArticleConditions(envDiff);

        const after = JSON.stringify(this.productCache);

        if (before !== after) {
            this.haveProductsChanged$.next(true);
        }
    }

    public getArticleConditionsFromCache() {
        return this.productCache.conditions;
    }

    public getArticleQuotationTextsFromCache() {
        return this.productCache.quotationTexts;
    }

    public clearCache() {
        this.productCache = {
            categories: [],
            conditions: [],
            quotationTexts: [],
        };
    }

    public fetchProductCategoryGroupNameMap() {
        const url = `${environment.http.configuration}material/groupMap?usecase=qapro`;
        return this.httpService.get(url);
    }
}
