import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from '../_shared/services/http.service';
import { ConfigitQuote, QuoteService } from '../_shared/services/quote.service';
import {
    OfferCreationResponse,
    Prices,
    PriceSummary,
    SetQuoteAsClosedParams,
} from '../_shared/components/price-summary/price-summary.model';
import { ProductGroup } from '../_shared/services/products.service';
import { PriceSummaryHelperService } from '../_shared/components/price-summary/price-summary.helper.service';
import { concatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class SummaryDataService {
    constructor(
        private httpService: HttpService,
        private helperService: PriceSummaryHelperService,
        private translate: TranslateService,
        private quoteService: QuoteService
    ) {}

    public createOffer(
        customerId: string,
        quotationRequestId: string,
        groupedProducts: ProductGroup[],
        lastPriceSummary: PriceSummary
    ) {
        const productList = groupedProducts.flatMap((group) =>
            group.products.map((product) => ({ ...product, group: this.translate.instant(group.translationKey) }))
        );
        const quote = this.quoteService.getCurrentQuote();
        const payload = this.helperService.getOfferCreationParams(customerId, productList, quote);
        payload.salesforceRequestId = quotationRequestId;
        const url = `${environment.http.quotation}offers`;
        return this.httpService.post<OfferCreationResponse>(url, payload).pipe(
            concatMap(({ documentNumber, expirationDate }) =>
                this.setQuoteAsClosed(quote.quote, {
                    sapDocumentNumber: documentNumber,
                    expirationDate,
                    customerId,
                    prices: <Prices>lastPriceSummary.prices,
                })
            )
        );
    }

    public getAvailablePrintTypes(salesOrg: string) {
        const url = `${environment.http.baseUrl}quote/printTypes?salesOrg=${salesOrg}`;
        return this.httpService.get(url);
    }

    public addReportingEntry(payload: object) {
        const url = `${environment.http.configuration}reporting`;
        return this.httpService.post(url, {
            appName: 'Quote Assist Pro',
            ...payload,
        });
    }

    private setQuoteAsClosed({ documentId, revision }: ConfigitQuote, payload: SetQuoteAsClosedParams) {
        const url = `${environment.http.baseUrl}quote/${documentId}/revision/${revision}/close`;
        return this.httpService.patch(url, payload);
    }
}
