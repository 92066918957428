import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, retryWhen, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UrlService } from '../_shared/services/url.service';
import { AppService } from '../_shared/services/app.service';
import { PrintType } from '../_shared/services/quote.service';

interface TaskDetails {
    id: string;
    state: string;
}

interface DocumentFile {
    objectType: string;
    documentUri: string;
    filename: string;
    mimeType: string;
}

export interface PdfDownload {
    url: string;
    type: string;
}

export interface PdfDownloadResult {
    isError: boolean;
    downloads: PdfDownload[];
}

@Injectable({
    providedIn: 'root',
})
export class NextStepsDataService {
    constructor(private urlService: UrlService, private http: HttpClient, private appService: AppService) {}

    public fetchOfferPdf(sapDocumentId: string, printType: PrintType): Observable<PdfDownloadResult> {
        // check if pdf is already available. if not, start generation process
        return this.tryDownload(sapDocumentId).pipe(
            concatMap(({ downloads }) =>
                downloads.length ? of({ isError: false, downloads }) : this.fetchPrintOfferPdf(sapDocumentId, printType)
            )
        );
    }

    private fetchPrintOfferPdf(sapDocumentId: string, printType: PrintType): Observable<PdfDownloadResult> {
        // first request to initiate task of generating pdf
        return this.createPdfTask(sapDocumentId, this.appService.getPrintType(printType)).pipe(
            delay(2000),
            // second request to check the state of pdf task (interval of 2000)
            concatMap(({ taskId }) => this.waitForTaskCompletion(taskId, sapDocumentId)),
            concatMap((result) =>
                this.notifySalesRep(sapDocumentId).pipe(
                    map(() => result),
                    catchError(() => of(result))
                )
            )
        );
    }

    private tryDownload(sapDocumentId: string): Observable<PdfDownloadResult> {
        return this.getFileList(sapDocumentId).pipe(
            map((list) => {
                const offers = list.filter((entry) => entry.objectType.startsWith('OFFER'));
                const downloads = offers.map((offer) => ({
                    url: `${environment.http.quotation}files/${sapDocumentId}/${offer.documentUri}`,
                    type: offer.objectType,
                }));
                return {
                    isError: false,
                    downloads,
                };
            })
        );
    }

    private waitForTaskCompletion(taskId: string, sapDocumentId: string) {
        return this.getTaskDetails(taskId).pipe(
            concatMap((res) => this.checkState(res.state, sapDocumentId)),
            retryWhen((error) =>
                error.pipe(delay(4000), take(15), (o) => concat(o, throwError('Retry limit exceeded!')))
            )
        );
    }

    private checkState(state: string, sapDocumentId: string): Observable<PdfDownloadResult> {
        if (['FINISHED_WITH_ERRORS', 'TERMINATED', 'FINISHED_ABORTED', 'FAILED'].includes(state)) {
            return of({ isError: true, downloads: [] });
        } else if (['FINISHED', 'SUCCEEDED'].includes(state)) {
            return this.tryDownload(sapDocumentId);
        } else {
            throw new Error();
        }
    }

    private createPdfTask(documentNumber: string, printType: string) {
        return this.createTask({
            documentNumber,
            printType,
            useExistingOfferPdf: false,
            sendEmailToSalesRep: false,
        });
    }

    private notifySalesRep(documentNumber: string) {
        return this.createTask({
            documentNumber,
            sendEmailToSalesRep: true,
            useExistingOfferPdf: true,
        });
    }

    private getFileList(sapDocumentId: string) {
        return this.http.get<DocumentFile[]>(`${environment.http.quotation}files/${sapDocumentId}`);
    }

    private getTaskDetails(taskId: string): Observable<TaskDetails> {
        return this.http.get<TaskDetails>(`${environment.http.quotation}offers/tasks/${taskId}`);
    }

    private createTask(payload: {
        documentNumber: string;
        useExistingOfferPdf: boolean;
        sendEmailToSalesRep: boolean;
        printType?: string;
    }) {
        const url = `${environment.http.quotation}offers/tasks`;
        return this.http.post<{ taskId: string }>(url, {
            type: 'EXPORT_OFFER',
            input: {
                ...payload,
                usecase: 'quote-assist-pro',
                locale: this.appService.getLanguageAsLocale(),
            },
        });
    }

    getAttachments(params): Observable<any> {
        const url = this.urlService.getUrl('next-steps.attachments', params);
        return this.http.get(url);
    }

    deleteAttachment(params): Observable<boolean> {
        const url = this.urlService.getUrl('next-steps.attachment.delete', params);
        return this.http.delete<boolean>(url).pipe(map(() => true));
    }

    downloadAttachment(params) {
        return this.urlService.getUrl('next-steps.attachment.download', params);
    }

    getQuoteEmailText(
        documentId: string,
        revision: string,
        language: string,
        country: string
    ): Observable<{ text: string }> {
        return this.http.get<{ text: string }>(
            `${environment.http.baseUrl}quote/${documentId}/revision/${revision}/mail-text`,
            {
                params: {
                    language,
                    country,
                },
            }
        );
    }

    getEmailRecipients(customerNumber: string) {
        return this.http.get(`${environment.http.companyUrl}${customerNumber}/contacts`);
    }

    public getExportTypes(salesOrg: string) {
        return this.http.get(`${environment.http.quotation}offers/export/types`, { params: { salesOrg } });
    }
}
