import { Injectable } from '@angular/core';
import { AppService } from '../../services/app.service';
import { PermissionService } from '../../services/permission.service';
import { ProductsCategoryService } from '../../services/products-category.service';
import { Product } from '../../services/products.service';
import { QuoteDiscount, QuoteDiscountsService } from '../../services/quote-discounts.service';
import {
    ConditionType,
    OfferCondition,
    OfferCreationParams,
    OfferItem,
    OfferParams,
    OfferPriceCalculationParams,
    OfferPrintOptions,
    OfferTexts,
    PriceLiteParams,
    UserRoleDependantOrderType,
} from './price-summary.model';
import { EnvironmentDiffService } from '../../services/environment-diff.service';
import { Quote } from '../../services/quote.service';

export interface ProductWithGroup extends Product {
    group?: string;
}

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryHelperService {
    private orderTypeMapping: { detailed: UserRoleDependantOrderType; 'target-price': UserRoleDependantOrderType } = {
        detailed: {
            employee: 'MECM',
            customer: 'MECK',
        },
        'target-price': {
            employee: 'MERM',
            customer: 'MERK',
        },
    };

    constructor(
        private permissionService: PermissionService,
        private quoteDiscountsService: QuoteDiscountsService,
        private appService: AppService,
        private productCategory: ProductsCategoryService,
        private environmentDiffService: EnvironmentDiffService
    ) {}

    public getOfferPriceCalculationParams(
        customerNumber: string,
        productList: ProductWithGroup[],
        quote: Quote
    ): OfferPriceCalculationParams {
        return {
            language: this.appService.getLanguageOnly(),
            offer: this.getOfferParams(customerNumber, productList, quote),
            printOptions: this.getPrintOptions(quote),
        };
    }

    public getOfferCreationParams(
        customerNumber: string,
        productList: ProductWithGroup[],
        quote: Quote
    ): OfferCreationParams {
        return {
            ...this.getOfferParams(customerNumber, productList, quote),
            printOptions: this.getPrintOptions(quote),
        };
    }

    public getLitePriceCalculationParams(products: ProductWithGroup[]): PriceLiteParams {
        const materials = products.map((p) => p.material);

        return {
            salesOrg: this.appService.getSalesOrg(),
            conditions: this.productCategory
                .getArticleConditionsFromCache()
                .filter(({ materialNumber }: { materialNumber: string }) =>
                    materials.some((material) => material === materialNumber)
                ),
            materials: products.map((p) => ({ materialNumber: p.material, amount: p.quantity })),
            priceAdvantageEnabled: false,
        };
    }

    private getOfferParams(customerNumber: string, productList: ProductWithGroup[], quote: Quote): OfferParams {
        const salesOrg = this.appService.getSalesOrg();
        const schemaId = quote.schemePdfs?.primary?.split(';')[0];

        const partnerDiscounts =
            this.permissionService.isUserAnEmployee && this.quoteDiscountsService.getQuoteDiscounts().length > 0
                ? this.quoteDiscountsService.getQuoteDiscounts()
                : [];
        const articleConditions = this.productCategory.getArticleConditionsFromCache();
        const articleQuotationTexts = this.productCategory.getArticleQuotationTextsFromCache();
        let index = 1;
        const items: OfferItem[] = productList.map((product) => {
            const partnerDiscount = partnerDiscounts.find(
                (d) => d.materialGroup === product.materialGroup
            )?.partnerDiscount;
            const articleDiscount = articleConditions.find(
                (d) => d.materialNumber === product.material
            )?.conditionValue;
            const conditions: OfferCondition[] = [
                partnerDiscount && { type: ConditionType.partner, value: Number(partnerDiscount) },
                articleDiscount && {
                    type: ConditionType.custom,
                    value: Number(articleDiscount),
                    currency: 'EUR',
                },
            ].filter(Boolean);
            const offerText = articleQuotationTexts.find((aqt) => aqt.materialNumber === product.material);
            const texts: OfferTexts = offerText ? { [offerText.id]: offerText.text } : undefined;
            return {
                itemNumber: index++,
                material: product.material,
                quantity: product.quantity,
                group: product.group,
                optional: product.optional,
                texts,
                conditions,
            };
        });
        const orderTypeFromCache = this.environmentDiffService.getOrderTypeFromCache();
        const role = this.permissionService.userInfo$.value.role;
        const orderType = (this.orderTypeMapping[orderTypeFromCache] || this.orderTypeMapping.detailed)[role];
        return {
            salesOrg,
            distrChan: '01',
            division: '01',
            customerPurchaseNumber: quote.quote.title,
            documentType: 'ZPAG',
            customerNumber,
            submissionNumber: quote.projectId,
            currency: quote.quote.currency,
            schemaId,
            offerType: orderType,
            quoteAssistInfo: '',
            items,
        };
    }

    private getPrintOptions(quote: Quote): OfferPrintOptions {
        return {
            printType: this.appService.getPrintType(quote.printType),
            printLocale: this.appService.getLanguageAsLocale(),
            textMap: {
                COVER: {
                    name: 'COVER',
                    textItems: [
                        {
                            textModuleName: 'ZAN',
                            textContent: null,
                        },
                    ],
                },
            },
            options: {
                draft: false,
                printItemPrices: true,
                printDiscounts: true,
                printLogo: true,
            },
        };
    }

    public hasProductListChanged(array1: any[], array2: any[]) {
        const arrayString1 = JSON.stringify(array1);
        const arrayString2 = JSON.stringify(array2);
        return arrayString1 !== arrayString2;
    }

    public quoteDiscountsDiffer(prev: QuoteDiscount[], current: QuoteDiscount[]): boolean {
        if (prev == null) {
            return true;
        }
        // 1. We convert each discount to a normalized string representation: GG_15
        // 2. we check the difference in both ways to determine if the arrays are equal
        const prevStrings = prev.map((it) => `${it.materialGroup}_${String(it.partnerDiscount)}`);
        const currentStrings = current.map((it) => `${it.materialGroup}_${String(it.partnerDiscount)}`);
        const diff1 = prevStrings.filter((it) => !currentStrings.includes(it));
        const diff2 = currentStrings.filter((it) => !prevStrings.includes(it));
        return diff1.length + diff2.length > 0;
    }

    public areAllProductCategoriesInProductList(products: Product[]) {
        const articleConditions = this.productCategory.getArticleConditionsFromCache();
        return (
            articleConditions?.length &&
            articleConditions.every(({ materialNumber }) => products.some((p) => p.material === materialNumber))
        );
    }
}
