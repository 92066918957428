import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuoteDiscountsService } from '../../services/quote-discounts.service';

export interface Discount {
    materialGroup: string;
    discount: number;
    maxDiscount: number;
}

export interface DiscountDialogData {
    discounts: Discount[];
}

@Component({
    selector: 'app-quote-discount-dialog',
    templateUrl: './quote-discount-dialog.component.html',
    styleUrls: ['./quote-discount-dialog.component.scss'],
})
export class QuoteDiscountDialogComponent implements OnInit {
    public columnsToDisplay = ['group', 'partnerDiscount', 'quoteDiscount'];
    public isLoading = false;

    public form: UntypedFormGroup;

    constructor(
        public dialogRef: MatDialogRef<QuoteDiscountDialogComponent>,
        private quoteDiscountsService: QuoteDiscountsService,
        @Inject(MAT_DIALOG_DATA) public data: DiscountDialogData
    ) {}

    public ngOnInit() {
        this.form = this.createFormGroup(this.data.discounts);
    }

    public createFormGroup(discounts: any[]) {
        const group: any = {};
        discounts.forEach((discount) => {
            const validators = [Validators.min(0), Validators.max(discount.maxDiscount)];
            group[discount.materialGroup] = new UntypedFormControl(
                this.quoteDiscountsService.getQuoteDiscount(discount.materialGroup).partnerDiscount,
                validators
            );
        });
        return new UntypedFormGroup(group);
    }

    public isValid(path: string) {
        return this.form.get(path).errors == null;
    }

    public firstErrorIs(path: string, errorName: string) {
        return this.getFirstError(path) === errorName;
    }

    public getFirstError(path: string) {
        if (this.form.get(path).errors == null) {
            return null;
        }
        const keys: string[] = Object.keys(this.form.get(path).errors);
        if (keys.length === 0) {
            return null;
        }
        return keys[0];
    }

    public close() {
        this.dialogRef.close();
    }

    public onSubmit() {
        if (this.form.valid) {
            const values = this.form.value;
            const quoteDiscounts = Object.entries(values).map(([key, value]) => ({
                materialGroup: key,
                partnerDiscount: String(value),
            }));
            this.quoteDiscountsService.setQuoteDiscounts(quoteDiscounts);
            this.dialogRef.close();
        }
    }
}
