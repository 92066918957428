import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';

interface UrlMapper {
    offline: string;
    http: string;
}

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor(private appService: AppService) {}

    private endpoints: { [key: string]: (args?: any) => UrlMapper } = {
        'quote.list': (args: any) => {
            args = args || {};
            let query = '';
            query = args.salesArea ? `${query}salesArea=${args.salesArea.trim()}` : query;
            query = args.partnerId ? `${query}&partnerId=${args.partnerId.trim()}` : query;
            query = this.paginationAndSortParams(query, args);

            return {
                offline: 'offer-list.json',
                http: `quote${query.length > 0 ? '?' : ''}${query}`,
            };
        },
        'quote.search': (args: any) => {
            let query = '';
            query = args.search ? `${query}title=${args.search.trim()}&` : query;
            query = args.fromDate ? `${query}createdAfter=${args.fromDate.toISOString()}&` : query;
            query = args.toDate ? `${query}createdBefore=${args.toDate.toISOString()}` : query;
            query = args.salesArea ? `${query}&salesArea=${args.salesArea.trim()}` : query;
            query = args.partnerId ? `${query}&partnerId=${args.partnerId.trim()}` : query;
            query = this.paginationAndSortParams(query, args);

            return {
                offline: 'offer-list.json',
                http: `quote?${query}`,
            };
        },
        'quote.create': () => {
            return {
                offline: '',
                http: 'quote',
            };
        },
        'quote.copy': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/copy`,
            };
        },
        'quote.delete': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}`,
            };
        },
        'quote.close': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/close`,
            };
        },
        'product.name': (args: any) => {
            return {
                offline: 'product-name.json',
                http: `material/${args.material}?salesAreaName=${args.salesAreaName}`,
            };
        },
        'price.summary': () => {
            return {
                offline: '',
                http: `price/calculate`,
            };
        },
        'pdf.energyLabel.download': (args: any) => {
            return {
                offline: '',
                http: `energy/${this.appService.getSalesOrg()}/${this.appService.getLanguageOnly()}/pdf/${args.hash}`,
            };
        },
        'employee.languages': () => {
            return {
                offline: '',
                http: `employee`,
            };
        },
        'customer.languages': () => {
            return {
                offline: '',
                http: `customer/data`,
            };
        },
        'quote.cancelKeys': () => {
            return {
                offline: '',
                http: `quote/cancel-keys?language=${this.appService.getLanguageOnly()}`,
            };
        },
        'quote.cancel': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/cancel`,
            };
        },
        'next-steps.attachments': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/attachments`,
            };
        },
        'next-steps.attachment.delete': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/attachments/${args.id}`,
            };
        },
        'next-steps.attachment.download': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/attachments/${args.documentUri}`,
            };
        },
        'next-steps.quote.mailText': (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/mail-text`,
            };
        },
        sendMailWithDocuments: (args: any) => {
            return {
                offline: '',
                http: `quote/${args.documentId}/revision/${args.latestRevision}/mail`,
            };
        },
        sendFeedback: () => {
            return {
                offline: '',
                http: `feedback`,
            };
        },
        customerDiscounts: (args: any) => {
            return {
                offline: '',
                http: `customer/${args.customer}/discounts?salesOrg=${this.appService.getSalesOrg()}`,
            };
        },
        validateVoucherCode: () => {
            return {
                offline: '',
                http: `voucher`,
            };
        },
    };

    private paginationAndSortParams(query: string, args: any) {
        query = args.page ? `${query}&page=${args.page}` : query;
        query = args.pageSize ? `${query}&perPage=${args.pageSize}` : query;
        query = args.sortBy ? `${query}&sortBy=${args.sortBy}` : query;
        query = args.sortOrder ? `${query}&sortOrder=${args.sortOrder}` : query;
        return query;
    }

    public getUrl(name: string, args?: object): string {
        // by having offline=1 in url we can enable offline mode
        if (environment.offline.enabled || window.location.href.includes(environment.offline.keyword)) {
            return environment.offline.baseUrl + this.endpoints[name](args).offline;
        }

        return environment.http.baseUrl + this.endpoints[name](args).http;
    }
}
