import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    first,
    map,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { FeatureService } from '../../services/feature.service';
import { HttpService } from '../../services/http.service';
import { PermissionService } from '../../services/permission.service';
import { ProductsCategoryService } from '../../services/products-category.service';
import { ProductsService } from '../../services/products.service';
import { QuoteDiscount, QuoteDiscountsService } from '../../services/quote-discounts.service';
import { QuoteService } from '../../services/quote.service';
import { SelectedCustomerService } from '../../services/selected-customer.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UrlService } from '../../services/url.service';
import { ProductListService } from '../product-list/product-list.service';
import { Discount, QuoteDiscountDialogComponent } from '../quote-discount-dialog/quote-discount-dialog.component';
import { PriceSummaryDataService } from './price-summary.data.service';
import { PriceSummaryHelperService, ProductWithGroup } from './price-summary.helper.service';
import { PriceSummary, PriceSummaryValidationError } from './price-summary.model';

@Component({
    selector: 'app-price-summary',
    templateUrl: './price-summary.component.html',
    styleUrls: ['./price-summary.component.scss'],
})
export class PriceSummaryComponent implements OnInit, OnDestroy {
    constructor(
        private dialog: MatDialog,
        private http: HttpService,
        private helperService: PriceSummaryHelperService,
        private productsService: ProductsService,
        private productListService: ProductListService,
        public appService: AppService,
        private urlService: UrlService,
        private snackBarService: SnackBarService,
        private translateService: TranslateService,
        public dataService: PriceSummaryDataService,
        public quoteDiscountsService: QuoteDiscountsService,
        public selectedCustomerService: SelectedCustomerService,
        public features: FeatureService,
        private permissionService: PermissionService,
        private quoteService: QuoteService,
        private productCategory: ProductsCategoryService,
        fb: UntypedFormBuilder
    ) {
        this.group = fb.group({
            voucherCode: [
                '',
                () => null,
                this.debouncedValueValidatorFactory(1000, this.asyncValidator.bind(this)).bind(this),
            ],
        });

        this.group
            .get('voucherCode')
            .statusChanges.pipe(
                filter((status) => status !== 'PENDING'),
                map((state) => [this.group.get('voucherCode').value, state]),
                map(([value, state]) => (state === 'VALID' ? value : '')),
                map((value: string) => value.toUpperCase()),
                distinctUntilChanged()
            )
            .subscribe((validValue) => {
                this.voucherCodeChanged.emit(validValue);
                this.currentVoucher = validValue;
                this.voucherCode$.next(validValue);
            });
    }

    private unsubscribe$: Subject<void> = new Subject<void>();
    private previousProductsValue: ProductWithGroup[];
    private previousQuoteDiscounts: QuoteDiscount[];

    public lang: string;
    public isLoading = false;
    public totalGross = 0;
    public discount = 0;
    public totalPrice = 0;
    public netPrice = 0;
    public vat = 0;
    public sum = 0;
    public voucherValue = 0;
    public currency: string;

    @Input()
    public isSummaryPage = false;

    @Input()
    public isExternalApp = false;

    @Input()
    public dataSource: any;

    @Output()
    public voucherCodeChanged = new EventEmitter<string>();

    public showPartnerSelectionHint = false;

    public group: UntypedFormGroup;

    public errorMessage: string;

    private voucherSet: boolean;

    private quoteDiscountDialog: MatDialogRef<QuoteDiscountDialogComponent>;

    // Voucher code

    public voucherCode$: BehaviorSubject<string> = new BehaviorSubject(null);

    public lastCustomer: any;

    public currentVoucher: string;
    public lastVoucherCode: string;
    public dirtyMatcher: ErrorStateMatcher = {
        isErrorState: (control: UntypedFormControl | null): boolean => !!(control?.invalid && control.dirty),
    };

    public ngOnInit() {
        this.subscribeToLanguageChanged$();
        this.subscribeToProductList$();

        if (this.permissionService.isUserAnEmployee) {
            this.selectedCustomerService.selectedCustomer$.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
                this.showPartnerSelectionHint = !value || (!value.name && !value.number);
            });
        }

        this.currency = this.quoteService.getCurrentQuote().quote.currency;
    }

    public showDiscountButton() {
        return this.permissionService.isUserAnEmployee;
    }

    public asFormControl(object: unknown): UntypedFormControl {
        return <UntypedFormControl>object;
    }
    public isVoucherSet(): boolean {
        return this.voucherSet;
    }

    public openPartnerDiscountDialog() {
        this.getCustomerDiscounts().subscribe(
            (discounts) => {
                this.quoteDiscountDialog = this.dialog.open(QuoteDiscountDialogComponent, {
                    data: {
                        discounts: this.filterDiscountsForQuoteDiscountDialog(discounts),
                    },
                });
            },
            () => {
                this.snackBarService.openSnackBar({
                    message: this.translateService.instant('QUOTE_DISCOUNT_DIALOG.ERROR.DISCOUNTS_UNAVAILABLE'),
                    isFailure: true,
                });
            }
        );
    }

    private fixDiscounts(discounts: Discount[]) {
        discounts.forEach((d) => {
            if (!d.maxDiscount) {
                d.maxDiscount = 70;
            }
        });

        const salesOrg = this.appService.getSalesOrg();
        if (['0500'].includes(salesOrg)) {
            const maxDiscountFix: Map<string, number> = new Map([
                ['GA', 46.5],
                ['GB', 46.5],
                ['GC', 46],
                ['GD', 26],
                ['GG', 49],
                ['GH', 53],
                ['GE', 50],
                ['GF', 25],
                ['GI', 44],
                ['GJ', 30],
                ['GL', 50],
                ['T1', 30],
                ['T2', 30],
                ['T3', 30],
                ['T4', 30],
                ['T5', 25],
            ]);
            const defaultDiscountFix: Map<string, number> = new Map([
                ['T1', 25],
                ['T2', 23],
                ['T3', 30],
                ['T4', 23],
                ['T5', 22],
            ]);

            discounts.forEach((d) => {
                if (maxDiscountFix.has(d.materialGroup)) {
                    d.maxDiscount = maxDiscountFix.get(d.materialGroup);
                }
                if (defaultDiscountFix.has(d.materialGroup)) {
                    d.discount = defaultDiscountFix.get(d.materialGroup);
                }
            });
        }

        return discounts;
    }

    private getCustomerDiscounts() {
        return this.selectedCustomerService.selectedCustomer$.pipe(
            first(),
            map((customer) => {
                return this.urlService.getUrl('customerDiscounts', {
                    customer: customer.number,
                });
            }),
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    private filterDiscountsForQuoteDiscountDialog(discounts): Discount[] {
        // PP-414 filter out discounts which are not used in material list
        const materialsInProductList = [...new Set(this.dataSource.data.map((it) => it.materialGroup).filter(Boolean))];
        const filteredDiscounts = discounts.filter((it) => materialsInProductList.includes(it.materialGroup));

        // PP-414 add material groups with 0 % partner discount when they exist in the product list
        //  - Find all materialsGroups available in the product list but are not included in the customers discounts
        const filteredDiscountMaterials = filteredDiscounts.map((it) => it.materialGroup);
        const materialsToBeAdded = materialsInProductList.filter(
            (it: string) => ![...filteredDiscountMaterials, 'N', 'C2'].includes(it)
        );
        const discountsToBeAdded = materialsToBeAdded.map((it) => ({
            materialGroup: it,
            discount: 0,
            maxDiscount: 0, // will be changed by fixDiscounts
        }));

        const useTheseDiscounts = [...filteredDiscounts, ...discountsToBeAdded];

        return this.fixDiscounts(useTheseDiscounts).filter((it) => it.maxDiscount > 0);
    }

    private asyncValidator(value: string) {
        this.voucherSet = false;
        if (value === undefined || value === '') {
            return of(null);
        }
        return this.selectedCustomerService.selectedCustomer$.pipe(
            first(),
            switchMap((customer) => {
                // if currently logged in user is a customer, just use the viCompanyId of the currently logged in user
                const isCustomerLoggedIn =
                    this.permissionService.userInfo$.value &&
                    this.permissionService.userInfo$.value.role === 'customer';

                let customerNumber = 'no-customer-selected';

                if (customer) {
                    customerNumber = customer.number;
                } else if (isCustomerLoggedIn) {
                    // use the viCompanyId
                    customerNumber = this.permissionService.userInfo$.value.viCompanyId;
                }

                const url = this.urlService.getUrl('validateVoucherCode');

                const salesOrg = this.appService.getSalesOrg();
                const language = this.appService.getLanguageOnly();
                const materialNumbers = this.dataSource.filteredData
                    .filter((data) => data.material)
                    .map((data) => data.material);
                const body = {
                    salesOrg,
                    language,
                    materialNumbers,
                    voucherCode: value.toUpperCase(),
                    customerNumber,
                };

                return this.http.post(url, body);
            }),
            map((response) => {
                if (response['valid'] === true) {
                    this.correctVoucherIsSet();
                    return null;
                } else {
                    this.errorMessage = response['messages'][0];
                    return { validationError: true };
                }
            }),
            catchError(() => of({ serverError: true }))
        );
    }

    private debouncedValueValidatorFactory(miliseconds: number, validator: (inputValue) => Observable<any>) {
        const debouncedSubject = new BehaviorSubject('');
        const debouncedObservable = debouncedSubject.pipe(debounceTime(miliseconds), distinctUntilChanged());
        return (control: UntypedFormControl) => {
            debouncedSubject.next(control.value);
            return debouncedObservable.pipe(first(), switchMap(validator));
        };
    }

    private correctVoucherIsSet() {
        this.voucherSet = true;
        if (this.quoteDiscountDialog && this.quoteDiscountDialog.getState() === MatDialogState.OPEN) {
            this.quoteDiscountDialog.close();
        }

        if (this.permissionService.isUserAnEmployee) {
            this.getCustomerDiscounts().subscribe((discounts) => {
                const discountsToBeRemoved = this.filterDiscountsForQuoteDiscountDialog(discounts);
                const updatedDiscounts = this.quoteDiscountsService
                    .getQuoteDiscounts()
                    .filter((qd) => !discountsToBeRemoved.some((d) => d.materialGroup === qd.materialGroup));
                this.quoteDiscountsService.setQuoteDiscounts(updatedDiscounts);
            });
        }
    }

    private subscribeToLanguageChanged$(): void {
        this.appService.onLanguageChanged$.pipe(takeUntil(this.unsubscribe$), filter(Boolean)).subscribe((lang) => {
            this.lang = <string>lang;
        });
    }

    private calculatePrice(products: ProductWithGroup[], customerId: string): Observable<PriceSummary> {
        if (this.isSummaryPage) {
            const params = this.helperService.getOfferPriceCalculationParams(
                customerId,
                products,
                this.quoteService.getCurrentQuote()
            );
            return this.dataService.fetchOfferPrice(params);
        } else {
            const param = this.helperService.getLitePriceCalculationParams(products);
            return this.dataService.fetchPriceSummaryLite(param);
        }
    }

    private fetchPriceSummary(products: ProductWithGroup[], quoteDiscounts: QuoteDiscount[], customerId: string): void {
        if (this.showPartnerSelectionHint) {
            return;
        }
        this.previousProductsValue = [...products];
        this.previousQuoteDiscounts = quoteDiscounts;

        this.dataService.hasErrors$.next({ value: false });

        this.calculatePrice(products, customerId).subscribe({
            next: (res) => {
                this.dataService.hasErrors$.next({ value: false });

                const totalAdvantage = -Math.abs(res.prices.totalAdvantage);
                this.totalGross = res.prices.totalGross;
                this.discount = totalAdvantage;
                this.totalPrice = res.prices.totalPrice;
                if (res.isFull) {
                    this.netPrice = res.prices.netPrice;
                    this.vat = res.prices.vat;
                    this.sum = res.prices.sum;
                }
                this.productsService.addPriceSummaryToCache(
                    res.prices.totalGross,
                    totalAdvantage,
                    res.prices.totalPrice,
                    res.isFull ? res.prices.netPrice : 0,
                    res.isFull ? res.prices.vat : 0,
                    res.isFull ? res.prices.sum : 0
                );
            },
            error: (error) => {
                this.dataService.hasErrors$.next({ value: true });
                if (error.status === 422 && error.error?.validationErrors) {
                    const { validationErrors } = <PriceSummaryValidationError>error.error;
                    const missingMaterials = validationErrors
                        .map((ve) => ve.context)
                        .filter(({ key }) => key === 'product')
                        .map(({ value }) => value)
                        .filter(Boolean);
                    if (this.permissionService.isUserAnEmployee) {
                        // tslint:disable-next-line:no-console
                        console.log(`Price calculation: The following materials are missing: ${missingMaterials}`);
                    }
                }
                if (this.features.enabled('allowFailingPriceCalc')) {
                    this.totalGross = 0;
                    this.discount = 0;
                    this.totalPrice = 0;
                    this.netPrice = 0;
                    this.vat = 0;
                    this.sum = 0;
                    this.voucherValue = 0;
                    this.productsService.addPriceSummaryToCache(0, 0, 0, 0, 0, 0, 0);
                } else {
                    this.snackBarService.openSnackBar({
                        message: this.translateService.instant('CONFIGURATION.SNACK_BAR.FETCH_PRICES.ERROR'),
                        isFailure: true,
                    });
                }
            },
        });
    }
    private selectedCustomerChanged(customer: any) {
        if (this.lastCustomer === customer) {
            return false;
        }
        this.lastCustomer = customer;
        return true;
    }
    private voucherChanged(value: string) {
        if (this.lastVoucherCode === value) {
            return false;
        }
        this.lastVoucherCode = value;
        return true;
    }

    private subscribeToProductList$(): void {
        const productList$: Observable<any> = this.productListService.productList$.pipe(
            takeUntil(this.unsubscribe$),
            filter(Boolean),
            debounceTime(1000)
        );

        const quoteDiscountList$: Observable<any> = this.quoteDiscountsService.quoteDiscounts().pipe(
            map((it) => it.filter((discount) => !!Number(discount.partnerDiscount))),
            filter(Boolean)
        );

        const selectedCustomer$ = this.selectedCustomerService.selectedCustomer$;

        combineLatest([
            productList$,
            quoteDiscountList$,
            selectedCustomer$,
            this.voucherCode$,
            this.productCategory.haveProductsChanged$.pipe(distinctUntilChanged()),
        ])
            .pipe(takeUntil(this.unsubscribe$), delay(10))
            .subscribe(([products, quoteDiscounts, selectedCustomer, voucherCode, productCategoriesChanged]) => {
                const hasProductListChanged = this.helperService.hasProductListChanged(
                    this.previousProductsValue,
                    products
                );
                const quoteDiscountsDiffer = this.helperService.quoteDiscountsDiffer(
                    this.previousQuoteDiscounts,
                    quoteDiscounts
                );
                const selectedCustomerChanged = this.selectedCustomerChanged(selectedCustomer);
                const voucherChanged = this.voucherChanged(voucherCode);
                if (products.length === 0) {
                    this.totalGross = 0;
                    this.discount = 0;
                    this.totalPrice = 0;
                    this.netPrice = 0;
                    this.vat = 0;
                    this.sum = 0;
                    this.voucherValue = 0;

                    this.productsService.addPriceSummaryToCache(0, 0, 0, 0, 0, 0, 0);
                } else if (
                    // fetch prices if products have changed or discounts have changed
                    // This is not great.
                    hasProductListChanged ||
                    quoteDiscountsDiffer ||
                    selectedCustomerChanged ||
                    voucherChanged ||
                    (productCategoriesChanged && this.helperService.areAllProductCategoriesInProductList(products))
                ) {
                    this.productCategory.haveProductsChanged$.next(false);

                    // if currently logged in user is a customer, just use the viCompanyId of the currently logged in user
                    const isCustomerLoggedIn =
                        this.permissionService.userInfo$.value &&
                        this.permissionService.userInfo$.value.role === 'customer';
                    let customerIdForClose = 'no-customer-selected';
                    if (selectedCustomer?.number) {
                        customerIdForClose = selectedCustomer.number;
                    } else if (isCustomerLoggedIn) {
                        // use the viCompanyId
                        customerIdForClose = this.permissionService.userInfo$.value.viCompanyId;
                    }

                    this.fetchPriceSummary(products, quoteDiscounts, customerIdForClose.padStart(10, '0'));
                }
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
