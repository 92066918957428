import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient) {}

    public get<T = any>(url: string, params?: HttpParams): Observable<T> {
        return <Observable<T>>this.http.get(url, { params });
    }

    public post<T>(url: string, payload?: any): Observable<T> {
        return <Observable<T>>this.http.post(url, payload);
    }

    public patch<T>(url: string, payload: any): Observable<T> {
        return <Observable<T>>this.http.patch(url, payload);
    }

    public delete<T>(url: string): Observable<T> {
        return <Observable<T>>this.http.delete(url);
    }
}
