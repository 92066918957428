import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigitSettings } from '../components/questionnaire/configit-quest-adapter/configit-types.interface';
import { PrintType } from './quote.service';

export interface ProductTranslations {
    [key: string]: string;
}

@Injectable({
    providedIn: 'root',
})
export class AppService {
    public readonly localStorageKeyLanguage = 'cultureLang';
    public readonly localStorageKeySalesorg = 'salesOrg';

    constructor(private translate: TranslateService) {}

    public configuration$: BehaviorSubject<ConfigitSettings> = new BehaviorSubject<ConfigitSettings>(undefined);

    public onLanguageChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.app.defaultLanguage);

    public availableLanguages$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    public salesOrg$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.configit.plant);

    public appTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public salesforceId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public getSalesAreaId() {
        const salesArea = this.getSalesArea();
        const { salesOrganization, distributionChannel, salesDivision } = salesArea;
        return `${salesOrganization}/${distributionChannel}/${salesDivision}`;
    }

    public getSalesOrg(): string {
        return this.salesOrg$.value;
    }

    private getSalesArea() {
        const salesOrg = this.getSalesOrg();
        const config = this.configuration$.value;
        if (config) {
            return config.allowedSalesAreas.find((salesArea) => salesArea.salesOrganization === salesOrg);
        }
        return null;
    }

    public setSalesOrg(salesOrg: string) {
        if (salesOrg) {
            localStorage.setItem(this.localStorageKeySalesorg, salesOrg);
            this.salesOrg$.next(salesOrg);
        }
    }

    public getSalesAreaName(): string {
        return this.getSalesArea()?.name;
    }

    public extractLanguage(languageAndCountry: string) {
        return languageAndCountry?.includes('-') ? languageAndCountry.split('-')[0] : languageAndCountry;
    }

    public getLocalStorageSalesOrg(): string {
        return localStorage.getItem(this.localStorageKeySalesorg);
    }

    public getLocalStorageLanguage(): string {
        return localStorage.getItem(this.localStorageKeyLanguage);
    }

    public getLanguage(): string {
        return this.onLanguageChanged$.value;
    }

    public getLanguageAsLocale() {
        return this.getLanguage().replace('-', '_');
    }

    public setLanguage(languageAndCountry: string) {
        if (languageAndCountry) {
            this.translate.use(languageAndCountry);
            localStorage.setItem(this.localStorageKeyLanguage, languageAndCountry);
            this.onLanguageChanged$.next(languageAndCountry);
        }
    }

    public getLanguageOnly() {
        return this.extractLanguage(this.getLanguage());
    }

    public getLocation() {
        const location = this.getLanguage().split('-')[1] || this.getLanguageOnly().toUpperCase();
        if (location === 'EN') {
            return 'GB';
        } else {
            return location;
        }
    }

    public getPrintType(printType: PrintType) {
        switch (printType) {
            case 'S':
                return 'SHORT';
            case 'M':
                return 'MIDDLE';
            case 'L':
                return 'LONG';
        }
    }

    public getBrowserLanguage() {
        return navigator.language;
    }

    public chooseTranslation(productTranslations?: ProductTranslations) {
        if (productTranslations === undefined) {
            return '';
        }

        return (
            productTranslations[this.getLanguage()] ||
            productTranslations[this.getLanguageOnly()] ||
            productTranslations[this.getBrowserLanguage()] ||
            productTranslations['de-DE'] ||
            ''
        );
    }

    public get isAuthorizedToUseTemplates() {
        const salesArea = this.getSalesArea();
        return salesArea?.templates?.enabled;
    }
}
