<app-card [isFooter]="true" [isHeader]="true" class="price-summary-card">
    <div header fxLayoutAlign="space-between center">
        <h2 class="title">{{ 'CONFIGURATION.PANEL.TITLE.TOTAL' | translate }}</h2>
        <div fxLayoutAlign="space-between baseline" *ngIf="isSummaryPage">
            <form
                [formGroup]="group"
                *ngIf="
                    !isExternalApp &&
                    features.enabled('voucherInput') &&
                    appService.getLocation().toUpperCase() === 'DE' &&
                    (selectedCustomerService.isPlannerOrArchitect$ | async) === false
                "
            >
                <ng-container *ngIf="group.get('voucherCode') as control">
                    <mat-form-field style="font-size: 14px; margin-right: 20px">
                        <mat-icon matPrefix class="assignment-icon">edit</mat-icon>
                        <input
                            matInput
                            [formControl]="asFormControl(control)"
                            [errorStateMatcher]="dirtyMatcher"
                            placeholder="{{ 'SUMMARY.VOUCHER_CODE_INPUT' | translate }}"
                        />
                        <mat-hint class="hint-ok" *ngIf="control.valid && control.value">{{
                            'OK' | translate
                        }}</mat-hint>
                        <mat-error *ngIf="control.hasError('validationError')">{{ errorMessage }}</mat-error>
                        <mat-error *ngIf="control.hasError('serverError')">{{
                            'SUMMARY.VOUCHER_CODE_ERROR.SERVER_ERROR' | translate
                        }}</mat-error>

                        <mat-progress-bar mode="indeterminate" *ngIf="control.pending"></mat-progress-bar>
                    </mat-form-field>
                </ng-container>
            </form>
            <button
                mat-raised-button
                color="primary"
                class="discount-dialog-button"
                (click)="openPartnerDiscountDialog()"
                *ngIf="showDiscountButton()"
                [disabled]="isVoucherSet()"
            >
                {{ 'QUOTE_DISCOUNT_DIALOG.BUTTON' | translate }}
            </button>
        </div>

    </div>

    <div body>
        <div *ngIf="!isSummaryPage && showPartnerSelectionHint">
            <div class="warning">{{ 'CONFIGURATION.PANEL.HINT.PARTNER_SELECTION' | translate }}</div>
        </div>
        <div *ngIf="!isSummaryPage && !showPartnerSelectionHint">
            <div
                fxLayout="row"
                fxLayoutAlign="space-between baseline"
            >
                <div class="small label total">{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_GROSS' | translate }}:</div>
                <div [attr.data-at-price-total]="totalPrice" class="value total">
                    {{ totalGross | currency: currency:'symbol-narrow' }}
                </div>
            </div>
        </div>

        <div *ngIf="isSummaryPage">
            <div fxLayout="row" fxLayoutAlign="space-between baseline">
                <div class="label">{{ 'CONFIGURATION.PANEL.LABEL.TOTAL_GROSS' | translate }}:</div>
                <div [attr.data-at-price-gross]="totalGross" class="value">
                    {{ totalGross | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between baseline" class="space"></div>
            <div
                *ngIf="(selectedCustomerService.isPlannerOrArchitect$ | async) === false"
                fxLayout="row"
                fxLayoutAlign="space-between baseline"
            >
                <div class="label light-total">{{ 'CONFIGURATION.PANEL.LABEL.NET_PRICE' | translate }}:</div>
                <div [attr.data-at-price-net]="netPrice" class="value light-total">
                    {{ netPrice | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between baseline">
                <div class="label">{{ 'CONFIGURATION.PANEL.LABEL.VAT' | translate }}:</div>
                <div [attr.data-at-price-vat]="vat" class="value">
                    {{ vat | currency: currency:'symbol-narrow' }}
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between baseline">
                <div class="label total">{{ 'CONFIGURATION.PANEL.LABEL.SUM_PRICE' | translate }}:</div>
                <div [attr.data-at-price-sum]="sum" class="value total">
                    {{ sum | currency: currency:'symbol-narrow' }}
                </div>
            </div>
        </div>
    </div>

    <div footer>
        <mat-progress-bar
            mode="indeterminate"
            *ngIf="(dataService.isPriceSummaryLoading$ | async)?.value"
        ></mat-progress-bar>
    </div>
</app-card>
