import { Injectable } from '@angular/core';
import { QuestPart } from '@vi/quest';
import { BehaviorSubject, EMPTY, Observable, of, timer } from 'rxjs';
import { debounce, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ConfigitConfigurationAndProducts } from '../components/questionnaire/configit-quest-adapter/configit-types.interface';
import { PackerConfigitQuestAdapterService } from '../components/questionnaire/configit-quest-adapter/packer-configit-quest-adapter.service';
import { ProductsCategoryService } from './products-category.service';
import { Quote, QuoteLine, QuoteService } from './quote.service';
import { EnvironmentDiffService } from './environment-diff.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationResultService {
    public configuration$: Observable<ConfigitConfigurationAndProducts | undefined>;

    public performance$: BehaviorSubject<QuestPart> = new BehaviorSubject<QuestPart>(undefined);

    constructor(
        private quoteService: QuoteService,
        private quests: PackerConfigitQuestAdapterService,
        private environmentDiffService: EnvironmentDiffService,
        private productCategoryService: ProductsCategoryService
    ) {
        this.configuration$ = this.quoteService.getCurrentQuoteObservable().pipe(
            debounce((v: Quote) => (v?.quote?.lines?.length > 1 ? timer(2500) : EMPTY)),
            switchMap((quote: Quote | undefined) => {
                if (quote) {
                    return this.getConfigurationForCurrentQuote(quote);
                }
                return of(undefined);
            }),
            shareReplay(1)
        );
    }

    private getConfigurationForCurrentQuote(q: Quote): Observable<ConfigitConfigurationAndProducts> {
        const primaryLine = this.quoteService.getPrimaryLine(q.quote);
        const secondaryLine = this.quoteService.getSecondaryLine(q.quote);
        const line = secondaryLine || primaryLine;
        return this.getConfigurationForQuoteLine(line).pipe(
            tap((model) => {
                const environmentDiff = model.configuration.environmentDiff;

                // add or remove CATEGORY-BDIS in products cache
                this.productCategoryService.updateCache(environmentDiff);

                // add schemes to cache
                const currentSchemes = this.environmentDiffService.getCurrentSchemes(environmentDiff);
                this.quoteService.updateSchemePdfs(currentSchemes);

                this.environmentDiffService.updateOrderTypeInCache(environmentDiff);
            })
        );
    }

    public getConfigurationForQuoteLine(line: QuoteLine): Observable<ConfigitConfigurationAndProducts> {
        const material = line?.variantCode || environment.quest.project;
        if (line) {
            const assignments = line.assignments
                .map((a) => {
                    if (a.isUserAssignment === undefined) {
                        a.isUserAssignment = !a.isDefault;
                    }
                    return a;
                })
                .filter((a) => a.isUserAssignment);
            return this.quests.getConfiguration(material, assignments).pipe(take(1));
        } else {
            return of({ configuration: {}, bomItems: [] });
        }
    }
}
