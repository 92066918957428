import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    Quest,
    QuestAnswerOption,
    QuestDisplayType,
    QuestFile,
    QuestFileUploadOptions,
    QuestionAppearance,
    QuestionType,
    QuestPart,
    QuestQuestion,
    QuestQuestionGroup,
    QuestQuestionValidate,
    QuestTransform,
    QuestValidators as validators,
} from '@vi/quest';
import { environment } from '../../../../../environments/environment';
import { ConfigitAssignmentAuthor } from './configit-assignment-author.enum';
import { ConfigitForceableDisplay } from './configit-forceable-display.enum';
import {
    ConfigitAssignment,
    ConfigitAssignmentsObj,
    ConfigitItem,
    ConfigitItemCustomProperties,
    ConfigitItemsObj,
    ConfigitModelOriginal,
    ConfigitQuestSettings,
    ConfigitValue,
} from './configit-types.interface';
import { ConfigitValueState } from './configit-value-state.enum';
import { ConfigitUtils as utils } from './configit.utils';

export class ConfigitQuestModelTransform implements QuestTransform {
    protected translate: TranslateService;
    protected config: ConfigitQuestSettings;

    constructor(config?: ConfigitQuestSettings, translate?: TranslateService) {
        this.config = config;
        this.translate = translate;
    }

    /**
     * Question types mapping - from controlType, customProperties.DisplayAs, dataType and isMultiValued properties
     * @see questionType
     *
     * @note dataType only for TextBox
     */
    protected questionTypes = {
        'ComboBox-Card': QuestionType.card,
        'ComboBox-DropDown': QuestionType.dropdown,
        ComboBox: QuestionType.dropdown,
        'ComboBox-Multi': QuestionType.checklist,
        'RadioButton-Boolean': QuestionType.checkbox,
        'RadioButton-RadioButton': QuestionType.radio,
        'TextBox-Int': QuestionType.integer,
        'TextBox-Float': QuestionType.float,
        'TextBox-InputField-Float': QuestionType.float,
        'TextBox-Date-String': QuestionType.date,
        'TextBox-File-String': QuestionType.file,
        'TextBox-FileUpload-String': QuestionType.file,
        'ComboBox-Hint': QuestionType.hint,
        'ComboBox-Hint-Multi': QuestionType.hint,
        'RadioButton-Hint': QuestionType.hint,
        'TextArea-Hint': QuestionType.hint,
        'TextBox-Hint': QuestionType.hint,
        'TextBox-Hint-Int': QuestionType.hint,
        'TextBox-Hint-Float': QuestionType.hint,
        'TextBox-Hint-String': QuestionType.hint,
    };

    /**
     * Allowed file types for upload
     */
    protected fileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

    /**
     * Allowed max file size for upload
     */
    protected fileMaxSize = 10485760; // 10MB

    /**
     * String treated as empty headline (subgroup so far)
     */
    protected anonymousIndicator = 'Anonymous';

    /**
     * Transforms original configit model into quest model
     */
    public transform(model: ConfigitModelOriginal): Quest {
        const id = model.template.name;
        const config = model.configuration;
        const original = model;
        const display = this.transformType(model);
        // extract by unique property
        const unique = 'fullyQualifiedName';
        const uigroups: ConfigitItemsObj = utils.objectize(model.template.uiGroups, unique);
        const variables: ConfigitItemsObj = utils.objectize(model.template.variables, unique);
        const states = utils.objectize([...config.uiGroupStates, ...config.variableStates], unique);
        const values = utils.objectize(model.assignments || [], 'variableName');
        // helper methods; subgroup assumes name with '.' (current configit implementation - connection by name only)
        const visible: (part: ConfigitItem) => boolean = (part) => part.show;
        const subgroup: (part: ConfigitItem) => boolean = (part) => /\./.test(part.fullyQualifiedName);
        const parent: (part: ConfigitItem) => string = (part) => part.fullyQualifiedName.replace(/\.[^.]+$/, '');
        // fix variables (insert) for subgroups parents
        Object.keys(uigroups).forEach((name) => {
            const group = uigroups[name];
            if (subgroup(group)) {
                const parentName = parent(group);
                const parentVariables = uigroups[parentName].variables || [];
                if (!parentVariables.includes(name)) {
                    parentVariables.push(name);
                }
            }
        });
        // then only visible groups as parts; and not subgroups
        const shown = config.uiGroupStates.filter(visible).filter((part) => !subgroup(part));
        const parts = shown.map((part: any) => this.transformPart(part, uigroups, variables, states, values));
        // Disabled every part after the invalid one
        let shouldDisable = false;
        parts.forEach((part) => {
            if (part.disabled === true) {
                part.valid = false;
            }
            if (shouldDisable) {
                part.disabled = true;
            } else if (!part.valid) {
                shouldDisable = true;
            }
        });

        return { id, display, original, parts };
    }

    /**
     * Prepare data for submit.
     */
    public prepare(model: Quest): any {
        return model;
    }

    /**
     * Sets quest type.
     */
    public transformType(model: ConfigitModelOriginal): QuestDisplayType {
        return environment.quest.modelSelectionVariable && model.template.name === environment.quest.project
            ? QuestDisplayType.single
            : QuestDisplayType.tabbed;
    }

    /**
     * Transform single part - combining original ui group within its variable and state info into quest part
     */
    protected transformPart(
        part: ConfigitItem,
        uigroups: ConfigitItemsObj,
        variables: ConfigitItemsObj,
        states: ConfigitItemsObj,
        values: ConfigitAssignmentsObj
    ): QuestPart {
        const id = <string>part.fullyQualifiedName;
        const uigroup = uigroups[id];
        const state = states[id];
        const title = uigroup.text;
        const icon = state.image && this.transformImageUrl(state.image, 100);
        const headline = uigroup.text;
        const description = this.transformImagePaths(<string>state.info);
        const shown = (uigroup.variables || []).filter((name: string) => states[name].show);
        // create groups if exists
        const subgroups = shown.map((name: string) => uigroups[name]).filter(Boolean);
        const groups = subgroups.length
            ? subgroups.map((group: ConfigitItem) => this.transformSubgroup(group, uigroups, variables, states, values))
            : undefined;
        // questions directly otherwise
        const questions = !groups
            ? shown.map((name: string) => this.transformQuestion(name, variables[name], states[name], values[name]))
            : undefined;
        // valid if none of groups neither questions (only those which have value or optional) reports error
        const errorprone = (questions || []).filter(
            (question: QuestQuestion) => question.value || (question.validate && question.validate.required)
        );
        const items: Array<QuestQuestionGroup | QuestQuestion> = groups || errorprone;
        let valid = (items.length && !items.some((item) => item.error !== false)) || undefined;
        const flatten = (arr: any[][]) => arr.reduce((prev, curr) => prev.concat(curr), []);
        const allNestedGroups: QuestQuestionGroup[] = groups ? flatten(groups.map((g) => this.recurseGroups(g))) : [];
        const allNestedQuestions: QuestQuestion[] =
            [...(questions || []), ...flatten(allNestedGroups.map((g) => g.questions || []))] || [];

        const required = (question: QuestQuestion) => question.validate && question.validate.required;

        allNestedQuestions?.forEach((question) => {
            if (question.error || (!question.value && required(question))) {
                valid = undefined;
            }
        });
        // control disabled
        const properties = (name: string) => variables[name] && variables[name].customProperties;
        const disability = (item?: ConfigitItemCustomProperties) => item && item.DisplayAs === 'Disabled' && item;
        const control = (uigroup.variables || []).map(properties).map(disability).filter(Boolean).pop();
        const disabled = control ? control.CalculatedValue === 'True' : undefined;

        return { id, title, icon, headline, description, valid, questions, groups, disabled };
    }

    private recurseGroups(group: QuestQuestionGroup): QuestQuestionGroup[] {
        const flatten = (arr: any[][]) => arr.reduce((prev, curr) => prev.concat(curr), []);
        const subs = (group.subGroups || []).map((g) => this.recurseGroups(g));
        return [group, ...flatten(subs)];
    }

    /**
     * Transforms image paths in text
     */
    protected transformImagePaths(text: string): string {
        const pattern = /src="(.+?\/images.+?)"/g;
        const links = [];
        let found = pattern.exec(text);

        while (found) {
            links.push(found[1]);
            found = pattern.exec(text);
        }

        links.forEach((link) => {
            text = text.replace(link, this.transformImageUrl(link, null));
        });

        return text;
    }

    /**
     * Transforms subgroup - transforming questions inside
     */
    protected transformSubgroup(
        item: ConfigitItem,
        uigroups: ConfigitItemsObj,
        variables: ConfigitItemsObj,
        states: ConfigitItemsObj,
        values: ConfigitAssignmentsObj
    ): QuestQuestionGroup {
        const id = item.fullyQualifiedName;
        const headline = (item.text !== this.anonymousIndicator && item.text) || '';
        const description = states[id].info;
        const shown = item.variables.filter((name: string) => states[name].show);

        // create subgroups if exists
        const childGroups = shown.map((name: string) => uigroups[name]).filter(Boolean);
        const subGroups = childGroups.map((group: ConfigitItem) =>
            this.transformSubgroup(group, uigroups, variables, states, values)
        );

        const questions = shown
            .filter((name: string) => !uigroups[name])
            .map((name: string) => this.transformQuestion(name, variables[name], states[name], values[name]));
        // set group invalid if any question (required) is invalid or not checked
        const errorprone = questions.filter((question) => question.value || question.validate?.required);
        const error = errorprone.some((question) => question.error !== false) || false;

        return { id, headline, description, subGroups, questions, error };
    }

    /**
     * Transforms question - combining original item and state into quest question
     */
    protected transformQuestion(
        id: string,
        item: ConfigitItem,
        state: ConfigitItem,
        set: ConfigitAssignment
    ): QuestQuestion {
        const unit = item.customProperties?.Unit;
        const text = item.text;
        const type = this.questionType(item);
        const hint = this.questionHint(type, item, state);
        const options = this.transformOptions(state);
        let value = this.transformValue(type, set, state);
        if (
            type === QuestionType.hint ||
            (state.readOnly && (type === QuestionType.float || type === QuestionType.integer))
        ) {
            value = this.transformNumericFormat(value, item.format);
        }
        const validate = this.transformValidation(type, item, state);
        const error =
            (!state.valid && state.invalidMessage) || (value ? !this.checkValidity(validate, value) : undefined);
        const appearance = this.questionAppearance(item);
        const template = type === QuestionType.file && this.transformQuestionFileTemplate(item);
        const upload = type === QuestionType.file && this.transformQuestionFileUpload(item);
        const disabled = state.readOnly;
        const placeholder = disabled ? '' : this.questionPlaceholder(type, validate); // empty for disabled
        // clear invalid value as already fully processed
        state.invalidValue = undefined;

        return {
            id,
            type,
            text,
            unit,
            hint,
            placeholder,
            value,
            options,
            error,
            validate,
            appearance,
            template,
            upload,
            disabled,
        };
    }

    protected transformQuestionFileTemplate(item: ConfigitItem): QuestFile {
        const local = !/^http/.test(item.customProperties.FileUrl);

        return {
            type: item.customProperties.FileType,
            size: item.customProperties.FileSize,
            url: (local ? environment.file.downloadUrl : '') + item.customProperties.FileUrl,
        };
    }

    protected transformQuestionFileUpload(item: ConfigitItem): QuestFileUploadOptions {
        if (item.customProperties.DisplayAs === 'FileUpload') {
            return {
                allow: this.fileTypes,
            };
        }
        return undefined;
    }

    /**
     * Transforms set value - from assignment
     */
    protected transformValue(type: QuestionType, assignment: ConfigitAssignment, state: ConfigitItem): any {
        switch (type) {
            case QuestionType.checkbox: {
                // if required must be undefined - like "untouched" (for mat-error)
                return (assignment && assignment.valueName === 'True') || (state.required ? undefined : false);
            }
            case QuestionType.file: {
                // re-storing file meta info from json for file
                return (assignment?.valueName && JSON.parse(assignment.valueName)) || '';
            }
            case QuestionType.text: {
                return state.invalidValue !== undefined ? state.invalidValue : assignment?.valueText;
            }
            case QuestionType.hint: {
                return assignment?.valueText;
            }
            default: {
                // value from assignment or set in state (for assignment error)
                return state.invalidValue !== undefined ? state.invalidValue : assignment?.valueName;
            }
        }
    }

    private transformNumericFormat(value: string | number, format: string) {
        // use numeric format from
        // https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-numeric-format-strings
        if (value && format) {
            const match = format.match(/[Ff](\d)/) || [];
            const precision = Number(match[1]);
            if (!isNaN(precision)) {
                // format delimiter to "," and add "." as delimiter for a thousand
                const formattedValue = Number(value).toFixed(precision);
                const parts = formattedValue.split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
        }
        return value;
    }

    /**
     * Transforms options for answer question
     */
    protected transformOptions(item: ConfigitItem): QuestAnswerOption[] {
        // visible options filter function
        const visible = (option: ConfigitValue) => {
            if (option.state === ConfigitValueState.Blocked || option.state === ConfigitValueState.Removed) {
                return false;
            }
            if (item.displayForceablesAs === ConfigitForceableDisplay.Hide) {
                return !this.isConflict(option);
            }
            return true;
        };
        // order forcable function
        const forceable = (option: ConfigitValue, prev: ConfigitValue) => {
            if (item.displayForceablesAs === ConfigitForceableDisplay.Last) {
                if (option.state === ConfigitValueState.Forceable) {
                    return prev.state !== ConfigitValueState.Forceable ? 1 : 0;
                } else {
                    return prev.state === ConfigitValueState.Forceable ? -1 : 0;
                }
            }
            return 0;
        };

        return item.valueStates
            .filter(visible)
            .sort(forceable)
            .map((option: ConfigitValue) => ({
                id: option.name,
                text: option.text,
                image: option.image && this.transformImageUrl(option.image),
                description: option.info,
                disabled: false, // by design not: option.state === ConfigitValueState.Forceable,
                custom: this.transformOptionCustom(option),
            }));
    }

    /**
     * Returns custom value for option's custom property
     */
    protected transformOptionCustom(item: ConfigitValue): string {
        return (this.isConflict(item) && 'conflicting') || '';
    }

    private isConflict(item: ConfigitValue) {
        return (
            item.state === ConfigitValueState.Forceable && item.assignmentAuthor !== ConfigitAssignmentAuthor.Default
        );
    }

    /**
     * Transforms image url
     */
    protected transformImageUrl(url: string, resize = 300): string {
        if (resize) {
            url = url.replace(/maxHeight=\d+/, `maxHeight=${resize}`);
        }

        return url.replace(/^(.+?\/images)\?(.+)/i, `${environment.configit.url}/configuration/images?$2`);
    }

    /**
     * Transforms validation settings for question
     */
    protected transformValidation(type: QuestionType, item: ConfigitItem, state: ConfigitItem): QuestQuestionValidate {
        return {
            email: item.customProperties && item.customProperties.DisplayAs === 'Email',
            date: type === QuestionType.date,
            integer: type === QuestionType.integer,
            float: type === QuestionType.float,
            min: item.customProperties?.MinValue,
            max: item.customProperties?.MaxValue || (type === QuestionType.file && this.fileMaxSize),
            required: state.required,
        };
    }

    /**
     * Evaluates question item to assign question type
     */
    protected questionType(item: ConfigitItem): QuestionType {
        // prepare criteria fields
        const controlType = item.controlType;
        const dataType = controlType === 'TextBox' && item.dataType; // data type only for text
        const multi = item.isMultiValued && 'Multi';
        const display = item.customProperties?.DisplayAs;
        const props = [controlType, display, dataType, multi].filter(Boolean);

        // then get from mapping (or default)
        return this.questionTypes[props.join('-')] || QuestionType.text;
    }

    /**
     * Gets question placeholder. Uses translations per type.
     *
     * @translate subc.quest.placeholder.email
     * @translate subc.quest.placeholder.text
     * @translate subc.quest.placeholder.dropdown
     * @translate subc.quest.placeholder.date
     * @translate subc.quest.placeholder.integer
     * @translate subc.quest.placeholder.float
     *
     * @translate subc.quest.placeholder.optional
     */
    protected questionPlaceholder(type: QuestionType, validate: QuestQuestionValidate): string {
        const optional = (!validate.required && ` ${this.translated('placeholder.optional')}`) || '';

        switch (type) {
            case QuestionType.date:
            case QuestionType.float:
            case QuestionType.integer:
            case QuestionType.dropdown: {
                return this.translated(`placeholder.${type}`) + optional;
            }
            case QuestionType.text: {
                const subtype = validate.email ? 'email' : type;
                return this.translated(`placeholder.${subtype}`) + optional;
            }
            default: {
                return '';
            }
        }
    }

    /**
     * Determines question hint
     */
    protected questionHint(
        _type: QuestionType,
        _item: ConfigitItem,
        state: ConfigitItem
    ): { text: string; important: boolean } {
        const isImportant = (info: string) => info && info.replace(/^\s*<.+?>/, '').substring(0, 1) === '!';
        let text = state.info;
        const important = isImportant(text);

        if (important) {
            text = text.replace(/!/, '');
        }

        return text && { text, important };
    }

    /**
     * Determines question appearance
     */
    protected questionAppearance(item: ConfigitItem): QuestionAppearance {
        switch (item.customProperties?.AppearanceWidth) {
            case 'HalfStandalone': {
                return QuestionAppearance.halfStandalone;
            }
            case 'Full': {
                return QuestionAppearance.full;
            }
            default: {
                return QuestionAppearance.half;
            }
        }
    }

    /**
     * Checks validity of question through defined validators
     *
     * Uses errors from quest validators:
     * @translate subc.quest.error.required
     * @translate subc.quest.error.email
     * @translate subc.quest.error.integer
     * @translate subc.quest.error.float
     * @translate subc.quest.error.min
     * @translate subc.quest.error.max
     */
    protected checkValidity(validate: QuestQuestionValidate, value: any): boolean {
        // helper methods
        const active: (rule: string) => any = (rule: string) => validate[rule];
        const validator = (rule: string) => validators[rule](validate[rule]);
        // get validator functions for active rules
        const checks: ValidatorFn[] = Object.keys(validate).filter(active).map(validator);
        // then check those
        const control = new UntypedFormControl(value, checks);

        return control.valid;
    }

    /**
     * Transforms custom data.
     */
    protected transformCustom(_1: ConfigitItemsObj, _2: ConfigitItemsObj): any {
        return;
    }

    /**
     * Gets translation for quest context
     */
    protected translated(text: string): string {
        if (this.translate) {
            return this.translate.instant(`${environment.quest.context}.${text}`);
        } else {
            return text;
        }
    }
}
